import React from 'react';

import { Box } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

// https://dev.to/admitkard/auto-generate-avatar-colors-randomly-138j

const getHashOfString = (str: string) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 8) - hash);
	}
	hash = Math.abs(hash);
	return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
	return Math.floor((hash % (max - min)) + min);
};

const hueRange = [0, 360];
const saturationRange = [50, 75];
const lightnessRange = [88, 92];

const generateHSL = (name: string) => {
	const hash = getHashOfString(name);
	const h = normalizeHash(hash, hueRange[0], hueRange[1]);
	const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
	const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
	return [h, s, l];
};

const useStyles = createStyles(({ colors }) => ({
	badge: {
		color: colors.gray[9],
		padding: '4px 6px',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: 'normal',
		borderRadius: '4px',
		width: 'fit-content',
	},
}));

interface Props {
	kind: string;
}

export const EntityBadge: React.FC<Props> = ({ kind }) => {
	const { classes } = useStyles();
	const [h, s, l] = generateHSL(kind);
	return (
		<Box className={classes.badge} style={{ backgroundColor: `hsl(${h}, ${s}%, ${l}%)` }}>
			{kind}
		</Box>
	);
};
