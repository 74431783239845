import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, createStyles } from '@tf/ui';
import type { FieldDefinition, VisualRule } from '@tf/utils';
import { RuleType } from '@tf/utils';

import { GenericField } from '../GenericField';
import { FileInput } from '../GenericField/inputs/FileInput';

interface Props {
	structFields: FieldDefinition[];
	def: FieldDefinition;
	idx?: number;
	hasFile: boolean;
	identity: BackendTypes.SegmentIdentity;
	isReadOnly: boolean;
	isRequiredFile: boolean;
	refs: (string | undefined)[];
	visualRules: VisualRule[];
}

const useStyles = createStyles(() => ({
	item: {
		marginBottom: '.75rem',
	},
}));

const StructField: React.FC<Props> = ({
	structFields,
	def,
	idx,
	hasFile,
	identity,
	isReadOnly,
	isRequiredFile,
	visualRules,
	refs,
}) => {
	const { classes } = useStyles();
	const isFileVisible = (visualRules: VisualRule[]) => {
		return !visualRules.find((item) => item.type === RuleType.DOCUMENT && item.rule === 'hide');
	};

	return (
		<>
			<Box>
				{structFields.map((fieldDef, index: number) => {
					return (
						<Box
							key={idx ? `${def.name}-${idx}-${index}` : `${def.name}-${index}`}
							className={classes.item}
						>
							<GenericField def={fieldDef} />
						</Box>
					);
				})}
				{hasFile && isFileVisible(visualRules) && (
					<Box className={classes.item}>
						<FileInput
							name={
								typeof idx === 'number'
									? `${identity.segmentKind}.${def.name}.${idx}`
									: `${identity.segmentKind}.${def.name}`
							}
							fieldPath={idx ? `${def.name}.${idx}` : def.name}
							isReadOnly={isReadOnly}
							isRequired={isRequiredFile}
							extensions={[...(refs.includes('DocumentCheckJobInfo') ? ['DOCUMENT_CHECK'] : [])]}
							isPDFToPNGEnabled={def.ref === 'common__document_with_check'}
						/>
					</Box>
				)}
			</Box>
		</>
	);
};

export default StructField;
